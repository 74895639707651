/*
 * @Autor: xin
 * @Date: 2022-10-31 16:01:33
 * @LastEditors: xin
 * @LastEditTime: 2022-11-01 14:40:07
 * @FilePath: \contribute\src\utils\elementModule.js
 * @Description: 
 */
import Vue from 'vue';
import { 
  Button, 
  RadioGroup, Radio,
  Input,
  Loading,
  Message
} from 'element-ui';

Vue.use(Button)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Input)

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;