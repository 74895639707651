<!--
 * @Autor: xin
 * @Date: 2022-10-31 15:29:12
 * @LastEditors: xin
 * @LastEditTime: 2022-11-01 10:52:41
 * @FilePath: \contribute\src\App.vue
 * @Description: 
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted () {
    console.log('APP', this.$route.query)
  },


}
</script>

<style lang="scss">
body {
  font-family: 'PingFang SC';
  margin: 0;
  padding: 0;
}
</style>
