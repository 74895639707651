/*
 * @Autor: xin
 * @Date: 2022-10-31 15:29:12
 * @LastEditors: xin
 * @LastEditTime: 2022-11-01 16:41:23
 * @FilePath: \contribute\src\router\index.js
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.query)
  if (to.query.token) {
    // localStorage.setItem('token', to.query.token)
    // localStorage.setItem('companyId', to.query.companyId)
  }
  next()
})

export default router
