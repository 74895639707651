/*
 * @Autor: xin
 * @Date: 2022-10-31 15:29:12
 * @LastEditors: xin
 * @LastEditTime: 2022-10-31 16:02:50
 * @FilePath: \contribute\src\main.js
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/utils/elementModule"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
